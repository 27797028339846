import { SharedAssetList } from '../components/SharedAsset/SharedAssetList';

function SharedMediaIndexRoute() {
  return <SharedAssetList />;
}

export const Component = SharedMediaIndexRoute;

export function clientLoader() {
  return null;
}
